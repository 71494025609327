
import { CompanyRepresentative } from "@/api";
import { CreateCRType } from "@/api/types";
import PageTitle from "@/components/common/PageTitle.vue";
import { PhoneCodes } from "@/mixins/countryCodes.mixin";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import ChangePasswordModal from "../common/ChangePasswordModal.vue";
import PhoneInput from "../common/PhoneInput.vue";
import RepresentativesAssign from "./RepresentativesAssign.vue";

@Component({
  components: {
    PageTitle,
    ValidationProvider,
    ValidationObserver,
    ChangePasswordModal,
    PhoneInput,
    RepresentativesAssign
  },
  mixins: [PhoneCodes]
})
export default class RepresentativesCreateForm extends Vue {
  @Prop({ default: "" })
  companyId!: string;
  @Prop({ required: false })
  currentRepresentative!: CreateCRType;

  tab = 0;
  initialRepresentative: CreateCRType = {
    id: "",
    email: "",
    name: "",
    phone: ""
  };
  representative = { ...this.initialRepresentative };

  processing = false;

  @Watch("currentRepresentative")
  onMounted() {
    if (this.currentRepresentative) {
      this.representative = { ...this.currentRepresentative };
    }
  }

  async addRepresentative() {
    this.processing = true;
    try {
      const data = { companies: [this.companyId], ...this.representative };
      if (this.currentRepresentative?.id) {
        const res = await CompanyRepresentative.editCR(
          data,
          this.currentRepresentative.id
        );
        this.$emit("crUpdated", res);
      } else {
        const res = await CompanyRepresentative.createCR(data);
        this.$success("Representative has been created successfully");
        this.$emit("crCreated", res);
      }
      this.close();
    } catch (error) {
      const err = error as any;
      (this.$refs.createRepresentativesForm as Vue & {
        setErrors: (errors: any) => void;
      }).setErrors(err.errors);
      this.$error(err.errors);
    } finally {
      this.processing = false;
    }
  }

  close() {
    this.representative = { ...this.initialRepresentative };
    this.tab = 0;
    this.$modal.hide("create-cr-modal");
    this.$emit("close");
  }
}
