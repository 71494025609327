
import { Companies } from "@/api";
import { Delegate, DelegatesWithPagination } from "@/api/types";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
@Component
export default class DelegatesList extends Vue {
  @Prop({ required: true })
  companyId!: number | string;
  @Prop({ required: true })
  companyType!: number | string;
  delegates: Array<Delegate> = [];
  delegatesWithPagination: DelegatesWithPagination = {
    meta: {
      perPage: 12
    }
  };
  nameFilter = "";
  options = {};
  page = 1;
  headers = [
    {
      text: "Company",
      value: "company"
    },
    {
      text: "Delegate",
      value: "name"
    },
    {
      text: "Passport / ID",
      value: "passport"
    },
    {
      text: "Phone number",
      value: "cell_phone"
    }
  ];
  timeout = 0;
  @Watch("options", { deep: true })
  async onOptionsChange(val: any) {
    await this.loadDelegatesData(val.page, "", this.companyType);
  }

  @Watch("nameFilter")
  async onFilterNameChange(val: any) {
    window.clearTimeout(this.timeout);
    this.timeout = window.setTimeout(async () => {
      await this.loadDelegatesData(this.page, val, this.companyType);
    }, 500);
  }

  @Watch("companyType")
  async onTypeChange(val: any) {
    await this.loadDelegatesData(val.page, "", this.companyType);
  }

  async loadDelegatesData(page = 1, name = "", type: string | number = "") {
    try {
      const delegatesWithPagination = await Companies.delegates(
        this.companyId,
        page,
        name,
        type
      );
      this.delegates = delegatesWithPagination.data
        ? delegatesWithPagination.data
        : [];
      this.delegatesWithPagination = delegatesWithPagination;
    } catch (e) {
      const err = e as any;
      await this.$error(err.errors);
    }
  }
  async onView(id: number) {
    // TODO: need implement this in future
    // console.log(": ", id);
  }
}
