
import { Companies, CompanyRepresentative } from "@/api";
import { CreateCRType } from "@/api/types";
import RepresentativesCreateForm from "@/components/companies/RepresentativesCreateForm.vue";
import { Component, Prop, Vue } from "vue-property-decorator";
import ChangePasswordModal from "../common/ChangePasswordModal.vue";

@Component({
  components: {
    RepresentativesCreateForm,
    ChangePasswordModal
  }
})
export default class RepresentativesCreate extends Vue {
  @Prop({ default: false })
  isEdit!: boolean;

  @Prop()
  companyId!: string;

  @Prop({ default: () => [] })
  crs!: any[];

  headers = [
    {
      text: "Name",
      value: "name"
    },
    {
      text: "Email",
      value: "email"
    },
    {
      text: "Phone",
      value: "phone"
    },
    {
      text: "Email Verification Status",
      value: "emailStatus",
      sortable: false,
      // align: "center"
      width: "180px"
    },
    {
      text: "Actions",
      value: "actions"
    }
  ];
  selectedCRId: string | null = null;

  representatives: any[] = [];
  selectedRepresentative: CreateCRType | null = null;

  gettingStatus: string | number | null = null;
  sendingEmail: string | number | null = null;
  unassigning: string | number | null = null;

  mounted() {
    this.representatives = this.crs;
  }

  onEdit(item: CreateCRType) {
    this.selectedRepresentative = item;
    this.$modal.show("create-cr-modal");
  }

  async onDelete(id: string) {
    const res = await this.$dialog.error({
      text: "Do you really want to delete item?",
      title: "Warning",
      persistent: false,
      actions: {
        No: { text: "No" },
        Yes: {
          color: "red",
          text: "Yes I do"
        }
      }
    });
    if (res === "Yes") {
      try {
        await CompanyRepresentative.removeCR(id);
        this.representatives = this.representatives.filter(cr => cr.id !== id);
      } catch (error) {
        const err = error as any;
        this.$error(err.data.errors || { error: err.statusText });
      }
    }
  }

  addRepresentative() {
    this.$modal.show("create-cr-modal");
  }

  onCRCreated(cr: CreateCRType) {
    this.representatives.push(cr);
  }

  crUpdated(cr: CreateCRType) {
    this.$success("Representative has been updated successfully");
    this.selectedRepresentative = null;
    const ind = this.representatives.findIndex(
      representative => representative.id === cr.id
    );
    this.representatives.splice(ind, 1, cr);
  }

  changePassword(item: any) {
    this.selectedCRId = item.id;
    this.$nextTick(() => {
      this.$modal.show("change-paswword-modal");
    });
  }

  async getStatus(delegate: CreateCRType) {
    const { id } = delegate;
    if (id) {
      this.gettingStatus = id;
      try {
        const { status } = await CompanyRepresentative.getVaultEmailStatus(id);

        delegate.emailStatus = status;
      } catch (e) {
        const err = e as any;
        this.$error(
          err?.errors || { err: err.result?.message || "Cant get status" }
        );
      } finally {
        this.gettingStatus = null;
      }
    }
  }

  async resentEmail(id: string) {
    if (!id) return;
    try {
      this.sendingEmail = id;
      await CompanyRepresentative.resendVaultVerificationEmail(id);
      this.$success("Verification email sent successfully");
    } catch (e) {
      const err = e as any;
      this.$error(
        err?.errors || { err: err.result?.message || "Cant send email" }
      );
    } finally {
      this.sendingEmail = null;
    }
  }

  unassign(id: string) {
    this.representatives = this.representatives.filter(cr => cr.id != id);
  }

  async onUnassign(id: string) {
    this.unassigning = id;
    try {
      const res = await Companies.unAssignCR(this.companyId, id);

      this.$success(
        res?.result?.message || "Company representative was unassigned"
      );

      this.unassign(id);
    } catch (error) {
      const err = error as any;
      this.$error(
        err.errors || {
          err: err.message || err.result?.message || "Something went wrong"
        }
      );
    } finally {
      this.unassigning = null;
    }
  }
}
