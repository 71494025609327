
import { Companies, CompanyRepresentative } from "@/api";
import { CompanyRepresentativesWithPagination } from "@/api/types";
import { TableOptions } from "@/types";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";

type Filter = { name: string; email: string };
type CrType = {
  id: string;
  name: string;
  email: string | null;
  companies: string[];
};

@Component({
  components: {}
})
export default class RepresentativesAssign extends Vue {
  @Prop({ default: "" })
  companyId!: string;
  representatives: any = [];
  assignedCrs: string[] = [];
  page = 1;
  limit = 5;
  loading = false;
  representativesWithPagination: CompanyRepresentativesWithPagination = {
    meta: {
      perPage: this.limit
    }
  };

  options: TableOptions = {} as TableOptions;

  processing: string | null = null;

  filter: Filter = { name: "", email: "" };

  headers = [
    {
      text: "Contact person",
      value: "name",
      sortable: false
    },
    {
      text: "Email",
      value: "email",
      sortable: false
    },
    {
      text: "Phone number",
      value: "phone",
      sortable: false
    },
    {
      text: "Action",
      value: "action",
      sortable: false
    }
  ];

  timeout: any = null;

  @Watch("options", { deep: true })
  async onOptionsChange(val: any, old: any) {
    console.log("options: ", val);

    this.limit = val.itemsPerPage;
    if (this.limit === -1) this.limit = 9999;
    if (old !== val) await this.loadCrs(val.page);
  }

  @Watch("filter", { deep: true })
  async onFilterNameChange(val: Filter) {
    window.clearTimeout(this.timeout);
    this.timeout = window.setTimeout(async () => {
      this.page = 1;
      await this.loadCrs();
    }, 500);
  }

  async loadCrs(page: number = this.page) {
    try {
      this.loading = true;
      const representativesWithPagination = await CompanyRepresentative.list(
        page,
        this.limit,
        this.filter
      );

      this.representatives = representativesWithPagination.data
        ? representativesWithPagination.data
        : [];
      this.representativesWithPagination = representativesWithPagination;
    } catch (e) {
      const err = e as any;

      await this.$error(
        err.errors || {
          err: err.message || err.result?.message || "Something went wrong"
        }
      );
    } finally {
      this.loading = false;
    }
  }

  async assignRepresentative(cr: CrType) {
    this.processing = cr.id;
    try {
      const res = await Companies.assignCR(this.companyId, cr.id);

      this.$success(
        res?.result?.message || "Company representative was assigned"
      );
      this.assignedCrs.push(cr.id);
      this.$emit("assign", cr);
    } catch (error) {
      const err = error as any;
      this.$error(
        err.errors || {
          err: err.message || err.result?.message || "Something went wrong"
        }
      );
    } finally {
      this.processing = null;
    }
  }

  async unAssignRepresentative(id: string) {
    this.processing = id;
    try {
      const res = await Companies.unAssignCR(this.companyId, id);

      this.$success(
        res?.result?.message || "Company representative was unassigned"
      );
      this.assignedCrs = this.assignedCrs.filter(el => el != id);
      this.$emit("unassign", id);
      this.loadCrs();
    } catch (error) {
      const err = error as any;
      this.$error(
        err.errors || {
          err: err.message || err.result?.message || "Something went wrong"
        }
      );
    } finally {
      this.processing = null;
    }
  }
}
